body {
  margin: 0;
  font-family: "Exo 2", "Roboto Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto", sans-serif !important;
}

.drag span{
 display: flex;
 flex-direction: column;
}

.form-select {
  font-size: 0.82rem !important;
}

#custom-tabs-four-tab .nav-item a {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 600;
}

.add-bg {
  color: #00a65a !important;
}

.add-bg:hover {
  background-color: #00a65a;
  color: #fff !important;
}

.edit-bg {
  color: #f89913 !important;
}

.edit-bg:hover {
  background-color: #f89913;
  color: #fff !important;
}

.delete-bg {
  color: rgb(208, 46, 28) !important;
}

.delete-bg:hover {
  background-color: rgb(208, 46, 28);
  color: #fff !important;
}

.card-primary.card-outline-tabs > .card-header a.active {
  border-top: 3px solid #677685;
}
#custom-tabs-four-tab .nav-item a {
  color: rgb(44, 44, 44);
  padding: 10px 20px;
}
#custom-tabs-four-tab .nav-item a.active {
  color: #054680;
}
.form-group p {
  font-size: 0.8rem;
  text-transform: uppercase;
  color: rgb(59, 49, 49);
  font-weight: 500;
}

.form-group input {
  font-size: 0.8rem;
  padding-left: 14px;
}

.redText {
  color: red;
}

.form-group-my.input-group-sm {
  display: flex;
  flex-direction: row;
  width: 70%;
  margin: 0 auto;

  margin-bottom: 10px;
}

.form-group-my input,
.form-group-my select {
  width: 50%;
}

.form-group-my p {
  width: 35%;
}

.productDetails {
  display: flex;
  row-gap: 10px;
  flex-direction: column;
}

.productImage img {
  width: 100%;
}

.productField a {
  text-decoration: none;
  color: #fff;
  font-size: 12px;
  background-color: #054680;
  padding: 8px 20px;
}

.btns-order {
  margin-top: 20px;
}

.messageBox {
  width: 100%;
}

.messageBox textarea {
  width: 100%;
  height: 100px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 12px;
}

.orderUser {
  display: flex;
  flex-direction: column;
}

.userField {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 14px;
  align-items: center;
  margin-bottom: 10px;
}

.userField h5 {
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  margin-right: 10px;
}

.userField a {
  color: #054680;
  text-decoration: none;
}

.messageBox button {
  border: none;
  padding: 5px 20px;
  background-color: #ec6b2e;
  color: #fff;
  font-size: 12px;
  transition: all 0.3s;
}

.messageBox button:hover {
  background-color: #e25513;
}

.messages {
  padding: 0px;
  margin: 0px;
  margin-bottom: 15px;
  font-size: 12px;
}

.messages li {
  list-style: none;
  padding: 0px;
  margin: 0px;
  padding: 10px;
  background-color: rgb(235, 235, 235);
  margin-bottom: 10px;
}

/*----------------------------------------*/
/*  1.  Ant custom CSS
/*----------------------------------------*/

.loginInput {
  margin-bottom: 30px;
}

.loginInput .ant-input-affix-wrapper {
  background: #373e4a !important;
  border: 1px solid #373e4a;
}

.loginInput .ant-input {
  background: #373e4a;
  font-size: 12px;
  color: #8e8e8e;
}

.loginInput .ant-input-prefix {
  color: #777777;
  margin-right: 8px;
}

.loginInput
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #383c41;
}

.loginInput .ant-input-password-icon.anticon {
  color: #777777;
}

.loginInput .ant-form-item-explain-error {
  text-align: left;
  font-size: 12px;
}

.loginBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: none;
  border: 1px solid #454a54;
  color: #fff;
  font-size: 14px;
}

.loginBtn:hover {
  background-color: #373e4a;
  color: #fff;
  border: 1px solid #36393f;
}

.loginBtn > .ant-btn-loading-icon {
  display: flex;
}

.loginBtn:hover,
.loginBtn:focus,
.loginBtn:active {
  text-decoration: none;
  background: #373e4a;
}

.loginBtn:hover,
.loginBtn:focus {
  color: #fff;
}

/*----------------------------------------*/
/*  2.  Nav side custom CSS
/*----------------------------------------*/

.nav-header {
  color: #6c737b !important;
}

.user-panel {
  align-items: center;
}

.user-panel .info {
  display: flex;
  flex-direction: column;
}

.user-control {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3px;
}

.user-tools {
  font-size: 8px;
  padding: 3px 6px;
  background-color: #222222;
  border-radius: 5px;
}

.nav-sidebar {
  font-size: 13px;
}

.nav-sidebar > .nav-item i {
  margin-left: 0.05rem !important;
  font-size: 12px !important;
  margin-right: 0.8rem !important;
  text-align: center !important;
  width: 10px !important;
}

/*----------------------------------------*/
/*  3.  count box CSS
/*----------------------------------------*/

.count-box {
  border-radius: 0.25rem;
  box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
  display: block;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.count-box > .inner {
  color: #fff;
}

.count-box .icon {
  position: absolute;
  right: 5px;
  bottom: 10px;
  z-index: 1;
  color: rgba(0, 0, 0, 0.1);
}

.count-box .icon i {
  font-size: 100px;
  line-height: 0;
  margin: 0;
  padding: 0;
  vertical-align: bottom;
}

.count-box .icon i:before {
  margin: 0;
  padding: 0;
  line-height: 0;
}

.count-box .inner h3 {
  color: #fff;
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 5px;
}

.bg-info {
  background-color: #f56954 !important;
}

.bg-info:hover {
  background-color: #f5563e !important;
}

.tile-green {
  background-color: #00a65a;
}

.tile-green:hover {
  background: #008d4c;
}

.tile-aqua {
  background: #00c0ef;
}

.tile-aqua:hover {
  background: #00acd6;
}

/*----------------------------------------*/
/*  4.  card CSS
/*----------------------------------------*/

.card {
  box-shadow: 0 0 1px rgb(0 0 0 / 5%), 0 1px 3px rgb(0 0 0 / 5%) !important;
}

.card-add {
  border-radius: 0px;
}

.card-add .card-header h3 {
  margin: 0px;
  font-size: 16px;
  padding: 5px 0px;
}

.card-custom {
  border-color: rgb(228 228 228);
}

.card-custom table {
  font-size: 12px;
}

.card-custom .card-title {
  margin: 0px;
  font-size: 14px;
  padding: 5px 0px;
}

.custom-card-header {
}

/*----------------------------------------*/
/*  3.  sub menu CSS
/*----------------------------------------*/

.page-sub-menu .ant-menu {
  background: none;
}

.page-sub-menu {
  margin-bottom: 30px;
}

/*----------------------------------------*/
/*  4.  Datatable CSS
/*----------------------------------------*/

.datatable-actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}

.datatable-card {
  margin-top: 35px;
  border-radius: 0px;
}

.datatable-card .card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(67 67 67 / 3%);
}

.datatable-card .card-header h3 {
  font-size: 14px;
  margin: 0px;
}

.card-body::after,
.card-footer::after,
.card-header::after {
  display: none;
}

.datatable-tool {
  border: none;
  font-size: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 6px 8px;
  border-radius: 5px;
  transition: all 0.2s ease-in;
}

.datatable-tool:hover {
  background-color: rgb(194, 194, 194);
  color: #fff;
}

.datatable-tools {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.datatable-header-tools {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.datatable-action {
  display: flex;
  gap: 5px;
  padding: 5px 14px;
  font-size: 12px;
  color: #000;
  background: none;
  border: 1px solid #ccc;
  transition: all 0.3s ease-in;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

.datatable-action i {
  font-size: 8px;
}

.navbar-expand {
  z-index: auto;
}

.ant-form-item-label label {
  font-size: 12px;
}

.ant-form-item-control-input-content input {
}

.ant-table table {
  font-size: 12px;
}

.tableBox {
  position: relative;
  margin: 20px 0px;
  overflow: auto;
}

.select-item-count {
  margin-bottom: 20px;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table td,
.custom-table th {
  border: 1px solid #ddd;
  padding: 5px;
}

.custom-table td {
  text-align: center;
}
.custom-table td:nth-child(2) {
  text-align: left;
}

.custom-table th {
  text-align: center;
}

.loader-box {
  position: absolute;
  width: auto;
  height: auto;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border: 1px solid rgb(225, 224, 224);
  background-color: #fff;
}
.loader {
  color: #3d3d3d;
  font-size: 5px;
  margin: 20px auto;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.load-main {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000000;
  background-color: rgba(0, 0, 0, 0.212);
}

@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

.tree-style {
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.card {
  border: 1px solid rgb(238, 238, 238);
}

.card-body {
  padding: 25px;
}

.card-title {
  margin-bottom: 0px;
  font-size: 14px;
  padding: 5px 0px;
}

.card-custom {
  overflow: hidden;
  padding: 8px;
}

.wrapper .content-wrapper {
  padding: 30px;
}

.container-fluid,
.content-header {
  padding: 0px;
  margin: 0px;
}

.container-fluid {
  margin-bottom: 20px;
}

.container-fluid h1 {
  font-size: 20px;
}

.content-header {
  border-bottom: 1px solid rgb(226, 226, 226);
  margin-bottom: 30px;
}

.control-bottons {
  display: flex;
  padding: 10px;
  justify-content: right;
  gap: 15px;
  flex-wrap: wrap;
}

.add-button {
  background-color: #008d4c;
  color: #fff;
}

.add-button:hover {
  background-color: #026335;
  color: #fff;
  border-color: #008d4c;
}

.table-image img {
  width: 50px;
}

.changePasswordBtn {
  background-color: #008d4c;
  border: none;
  color: #fff;
  padding: 5px 10px;
  font-size: 10px;
  border-radius: 10px;
  transition: all 0.3s;
}

.changePasswordBtn:hover {
  background-color: #01743e;
}

.modal__excel_body {
  padding: 10px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  justify-items: center;
}


.choise-service {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  gap: 20px;
  margin-bottom: 20px;
}

.choise-service-detials {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.choise-service-detials h5 {
  font-size: 16px;
}

.choise-title {
  display: flex;
  flex-direction: column;
}

.choise-title span {
  font-size: 13px;
}

.choise-service-price {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  gap: 10px;
}

.remove-cart-item {
  font-size: 12px;
  font-weight: 400;
  color: rgb(148, 7, 7);
  cursor: pointer;
}

.choise-img {
  flex: 0 0 80px;
  width: 80px;
}

.choise-img img {
  width: 80px;
  height: 80px;
}

.btn-booking {
  width: 100%;
  height: 56px;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #54c7d7;
  background-color: #54c7d7;
  transition: all 200ms ease-in-out 0s;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 0px;
}

.btn-booking:hover {
  background-color: #40b6c5;
}

.price-bar {
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 24px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 16px;
}

.price-bar-sub {
  font-size: 13px;
  color: rgb(134, 134, 134);
}

.service-list {
  display: flex;
  flex-direction: column;
}

.service-list li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.service-list li p {
  font-size: 13px;
}

.service-list li span {
  font-size: 13px;
  font-weight: bold;
}

.page_detials_sub {
  margin: 20px 0px;
}

.page_detials_sub h4 {
  font-size: 16px;
}

.booking_sub {
  margin: 15px 0px;
}

.booking_sub h4 {
  font-size: 14px;
}

.booking-details div {
  padding: 7px;
  font-size: 13px;
}